*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    height: 100%
}

body{
    height: 100%
}