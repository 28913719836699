:root{
    --title-color:rgb(207,51,65);
    --body-text-colour: rgb(75,75,75);

    --alter-background: rgb(241,241,241);
}

.mapDiv{
    background-color: white;
    width: 100%;
    padding: none;
}

.iconStyle{
    width: 1.5em;
    background-color: transparent;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 80%;
    gap: 15px;
    background-color: white;
    max-width: 1000px;
    -webkit-border-radius: 25px;
}

.contactPointer
{
    cursor: pointer;
    transition: all 0.4s ease;
}

.contactPointer:hover
{
    font-size: 18px;
}

.headerDiv > h1{
    color: var(--title-color);
    text-align: center;
}

.headerDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: var(--body-text-colour);
    width: 80%;
}

.outerDetailDiv{
    padding-top: 25px;
    background-color: var(--alter-background);
}

.outerDetailDiv > h1{
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    text-align: center;
}   


.detailDiv{
    min-height: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 80%;
    max-width: 800px;
    font-family: 'Open Sans', sans-serif;
}

.detailFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.detailElement{
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--body-text-colour);
    font-size: 16px;
}

.detailAddressElement{
    color: var(--body-text-colour);
    font-size: 16px;
}

.socialButtons
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateX(-5px);
}

.socialIconStyle
{
    width: 3em;
}

#bold{
    font-weight: 600;
}

#disclaim{
    font-size: 12px;
}

.outerTestimonials{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.outerTestimonials > h1{
    color: black;
    text-align: center;
}

.testimonials{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1000px;
    width: 80%;
    padding-top: 20px;
    gap: 20px;

    justify-items: center;
}

.quote{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 10px lightgrey;
    padding: 1em;
    -webkit-border-radius: 25px;

    max-width: 400px;
    width: 100%;
}

.quote > p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    color: var(--body-text-colour);
    font-style: italic;
    font-size: 15px;
}

@media (min-width: 600px){
    .headerDiv{
        transform: translateY(-50px);
    }
}

@media (max-width: 900px)
{
    .headerDiv > p{
        width: 100%;
    }

    .detailDiv{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .testimonials{
        grid-template-columns: 1fr;
    }
}