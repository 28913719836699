:root{
    --sign-colour-red: rgb(207,51,65);
    --body-text-colour: rgb(75,75,75);

    --alter-background: rgb(241,241,241);
}

.faqHeaderDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px;
    gap: 0.5em;
    min-height: 200px;
}

.faqHeaderDiv > h1{
    color: var(--sign-colour-red);
    text-align: center;
}

.faqHeaderDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: var(--body-text-colour);
    max-width: 550px;
    text-align: center;
}

.iconStyle
{
    width: 1.5em;
    background-color: transparent;
}

.faqQuestionsDiv{
    background-color: var(--alter-background);
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: centern;
    gap: 1em;
    padding-top: 30px;
    padding-bottom: 30px;
}

.questions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.question{
    width: 40vw;
    max-width: 400px;
    min-height: 225px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    border-radius: 5%;
    gap: 1em;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Open Sans', sans-serif;
    justify-content: center
}

.question > h4{
    text-align: center;
}

.question > p {
    text-align: center;
}

.question > ul{
    list-style: none;
    padding-bottom: 10px;
}

.question > span{
    font-size: 26px;
    font-weight: 600;
    font-family: 'Helvetica';
}

.bold{
    font-weight: 600;
}

#iconHourGlass{
    width: 1em;
}

@media (max-width: 650px)
{
    .questions{
        grid-template-columns: 1fr;
    }

    .question{
        width: 70vw;
        max-width: none;
    }
}