:root{
    --alter-background: rgb(241,241,241);
    --body-text-colour: rgb(75,75,75);
}

.headImageContainer{
    max-height: 300px;
    aspect-ratio: 1/2;
    width: 100%;
    overflow: hidden;

    position: relative;
}

.headImage{
    object-position: 100% 60%;
}

#hashImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
}

.infoImgCover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    transition: all 4s ease;
}

.imgInvisible{
    opacity: 0;
}

.iconStyle{
    width: 1.5em;
    background-color: transparent;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 100px;
}

.headerDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 80%;
    gap: 15px;
    background-color: white;
    max-width: 1000px;
    -webkit-border-radius: 25px;
    max-height: 100px;
}

.headerDiv > h1{
    color: var(--title-color);
    text-align: center;
}

.headerDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: var(--body-text-colour);
    width: 80%;
}

@media (min-width: 600px){
    .headImage{
        object-position: 100% 50%;
    }

    .headerDiv{
        transform: translateY(-50px);
        padding-top: 2.5em;
    }

    .headerContainer{
        max-height: 70px;
    }
}

@media (min-width: 900px){
    .headImage{
        object-position: 100% 60%;
    }
}




.introductionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    padding-top: 0em;
}

.introductionDiv > h2, p{
    font-family: 'Open Sans', sans-serif;
}

.introductionDiv > h2{
    padding-bottom: 0.5em;
}

.introductionDiv > p{
    font-size: 16px;
    color: var(--body-text-colour);
    padding-top: 0.5em;
    width: 100%;
    max-width: 1000px;

    padding: 0.5em;
    text-align: center;
}

.outerPanelDiv{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2em;
}

.panelDiv{
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5em;
    row-gap: 2em;

    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
}

.panel{
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    width: 30%;
    min-width: 300px;
    border-radius: 5%;
}

.panelImg{
    background-color: lightgrey;
    aspect-ratio: 1.8 /1;

    overflow: hidden;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;

    position: relative;
}

.panelText{
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 1em;
    padding-bottom: 2em;
}

.panelText > h3, p{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

#tipperImage{
    object-position: center 60%;
}

@media (max-width: 650px)
{
    .panelDiv{
        grid-template-columns: 1fr;
    }
}