:root
{
    --title-color:rgb(207,51,65);
    --tel-text: rgb(70,57,57);
    --tel-highlightes-text: rgb(47, 47, 163);

    --sign-colour-blue: rgb(8,37,182);
    --sign-colour-yellow: rgb(255,191,64);
    --sign-colour-red: rgb(207,51,65);

    --background-colour: white;

    --header-height: 90px;

    --max-height: 110px;
    --min-height: 90px;

    --navbar-translate: -100%;

    --wide-nav-height: 0px;
 
    --overflow-var: auto;
    overflow: var(--overflow-var);

    /* If changing must change max-width because var does not work :( */
    --change-point: 700px;
}

a
{
    text-decoration: none;
    color: inherit;
}

.navbarBody
{
    display:flex;
    justify-content:space-around;
    overflow: hidden;
    height: var(--header-height);
    position: fixed;
    left: 0;
    top: -0.5%;
    right: 0;
    background-color: var(--background-colour);
    z-index: 10;
}

.titleContainer
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
}

.title
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--title-color);
}

.title > p
{
    white-space: nowrap;
}

#primaryTitle
{
    font-size: 32px;
    font-family: 'Bebas Neue', 'Helvetica';
}

#secondaryTitle
{
    font-size: 28px;
    font-family: 'Bebas Neue', 'Helvetica';
}

.titleIcon
{
    max-height: var(--header-height);
}

.dropDown{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
}

.navIcon
{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    color: brown;
    display: none;
}

#invisible{
    width: 0;
    height: 0;
}

.phoneIcon
{
    width: 1em;
    height: 1em;
    display: none;
    cursor: pointer;
    color: brown;
}

.phoneDiv
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex:1;
    margin-right: 5px;
    font-family: 'Open Sans', sans-serif;
}

.contactNumbers > ul
{
    list-style-type: none;
    text-align: right;
}

.contactNumbers > ul > li
{
    color: var(--tel-text);
    font-size: 18px;
}

#contactPointer{
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.4s ease;
}

#contactPointer:hover,
#contactPointer:focus
{
    font-size: 20px;
    color: rgb(125, 120, 120);
}

.navbarBody, #headerBlock
{
    transition: height 0.4s ease;
}

img
{
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

#headerBlock
{
    height: calc(var(--header-height) + var(--wide-nav-height) - 5px);
}

.mobileLinkBody{
    display: none;
    position: fixed;
    top: calc(var(--header-height) - 5px);
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    transition: tranform 1s ease-in-out;
    transform: translateY(var(--navbar-translate));
    z-index: 10;
}

.mobileLinkContainer{
    width: 100%;
    height: 100%;
    background-color: transparent;
    
    padding-top: 2.5em;
    padding-bottom: 8em;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    gap: 1em;

    overflow: scroll;
}

.mobileLinkText{
    font-family: 'Bebas Neue', 'Helvetica';
    min-height: 2.5em;
    width: 50%;
    box-shadow: 0px 0px 10px lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

.mobileLinkText:hover{
    min-height: 3em;
    box-shadow: 0px 0px 10px grey;
}

.wideLinkBody{
    position: fixed;
    top: calc(var(--header-height) - 5px);
    left: 0;
    right: 0;
    background-color: var(--sign-colour-blue);
    color: white;
    white-space: nowrap;    
    transition: all 0.4s ease;
    z-index: 10;
    min-height: 4em;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wideLinkContainer{
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5em;
    padding: 10px;
    justify-content: center;
}

.wideLinkText{
    display: flex;
    justify-content: flex-start;
    font-family: 'Bebas Neue', 'Helvetica';
    min-height: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    font-size: 20px;
}

.wideLinkText:hover{
    font-size: 24px;
}

@media(max-width: 700px)
{
    :root{
        --header-height: 75px;
        --max-height: 100px;
        --min-height: 75px;
    }

    .phoneIcon{
        display: block;
    }

    .contactNumbers{
        display: none;
    }

    #primaryTitle
    {
        font-size: 24px;
    }

    #secondaryTitle
    {
        font-size: 20px;
    }

    .navIcon{
        display: block;
    }

    .wideLinkBody{
        display: none;
    }

    #headerBlock
    {
        height: calc(var(--header-height) - 5px);
    }

}

@media(max-width: 250px)
{
    .titleIcon{
        display: none;
    }
}