:root{
    --alter-background: rgb(241,241,241);
    --body-text-colour: rgb(75,75,75);

    --sign-colour-blue: rgb(8,37,182);
    --sign-colour-yellow: rgb(255,191,64);
    --sign-colour-red: rgb(207,51,65);
}

.headImageContainer{
    max-height: 300px;
    aspect-ratio: 1.7/1;
    width: 100%;
    overflow: hidden;

    background-color: var(--alter-background);

    position: relative;
}

.headImage{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 100% 30%;
}

.iconStyle{
    width: 1.5em;
    background-color: transparent;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 80%;
    gap: 15px;
    background-color: white;
    max-width: 1000px;
    -webkit-border-radius: 25px;
}

.headerDiv > h1{
    color: var(--title-color);
}

.headerDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: var(--body-text-colour);
    width: 80%;
}

@media (min-width: 600px){
    
    .headerDiv{
        transform: translateY(-50px);
    }
}

.introductionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    background-color: var(--alter-background);
}

.introductionDiv > h2, p{
    font-family: 'Open Sans', sans-serif;
}

.introductionDiv > h2{
    padding-bottom: 0.5em;
    text-align: center;
}

.introductionDiv > p{
    font-size: 16px;
    color: var(--body-text-colour);
    padding-top: 0.5em;
    width: 100%;
    max-width: 1000px;

    padding: 0.5em;
    text-align: center;
}




.outerPanelDiv{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;
}

.panelDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2em ;
    row-gap: 1em ;
    width: 100%;
    max-width: 80%;
    padding: 1em;
}

.panel{
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    flex: 1;
    border-radius: 5%;

    
    overflow: hidden;
}

.panelImg{
    background-color: rgb(141, 134, 134);
    aspect-ratio: 1.8 /1;

    overflow: hidden;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    position: relative;
}

.panelText{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    height: 100%;

    padding: 2em;
    padding-top: 1em;
    color: white;
}

.panelText > h3, p{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

.panelText > ul{
    font-family: 'Open Sans', sans-serif;
    padding-left: 1em;
    padding-right: 1em;
    align-self: center;
    
}

.panel:nth-child(3n-2) > .panelText{
    background-color: var(--sign-colour-blue);
}

.panel:nth-child(3n-1) > .panelText{
    background-color: var(--sign-colour-yellow);
}

.panel:nth-child(3n) > .panelText{
    background-color: var(--sign-colour-red);
}

@media(max-width: 1000px){
    .panelDiv{
        grid-template-columns: 1fr;
    }
}


.materialDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.materialElem{
    aspect-ratio: 1.4/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--alter-background);
    
    overflow: hidden;
    position: relative;
}

.materialElem:nth-child(odd){
    background-color: grey;
}

.materialTextbox{

    height: 80%;
    width: 80%;
    border: 10px solid white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.materialTextbox > h3{
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    z-index: 1;
}

#hashImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
}

.infoImgCover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    transition: all 4s ease;
}

.imgInvisible{
    opacity: 0;
}

@media (max-width:1000px){
    .materialDiv{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:600px){
    .materialDiv{
        grid-template-columns: 1fr;
    }
}
