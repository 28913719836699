:root
{
    --title-color:rgb(207,51,65);
    --regular-text: rgb(36, 35, 35);

    --sign-colour-blue: rgb(8,37,182);
    --sign-colour-yellow: rgb(255,191,64);
    --sign-colour-red: rgb(207,51,65);

    --alter-background: rgb(241,241,241);
}

.headImageContainer{
    max-height: 300px;
    aspect-ratio: 1.7/1;
    width: 100%;
    overflow: hidden;

    position: relative;
}

.iconStyle{
    width: 1.5em;
    background-color: transparent;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 80%;
    gap: 15px;
    background-color: white;
    max-width: 1000px;
    -webkit-border-radius: 25px;
}

.headerDiv > h1{
    color: var(--title-color);
    text-align: center;
}

.headerDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: var(--body-text-colour);
    width: 80%;
}

@media (min-width: 600px){
    
    .headerDiv{
        transform: translateY(-50px);
    }
}

.introductionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    background-color: var(--alter-background);
}

.introductionDiv > h2, p{
    font-family: 'Open Sans', sans-serif;
}

.introductionDiv > h2{
    padding-bottom: 0.5em;
}

.introductionDiv > p{
    font-size: 16px;
    color: var(--body-text-colour);
    padding-top: 0.5em;
    width: 100%;
    max-width: 1000px;

    padding: 0.5em;
    text-align: center;
}

.outerPanelDiv{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;
}

.panelDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2em ;
    row-gap: 1em ;
    padding: 1em;
    max-width: 95%;
}

.panel{
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5%;

    overflow: hidden;
}

.panelImg{
    aspect-ratio: 1.8 /1;
    max-height: 300px;

    overflow: hidden;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;

    position: relative;
}

#hashImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
}

.infoImgCover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    transition: all 4s ease;
}

.imgInvisible{
    opacity: 0;
}

.panelText{
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding: 2em;
    padding-top: 1em;
    color: white;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.panelText > h3, p{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    
}

.panelText > ul{
    font-family: 'Open Sans', sans-serif;
    padding-left: 1em;
    padding-right: 1em;
    align-self: center;
    
}

.panel:nth-child(3n-2) > .panelText{
    background-color: var(--sign-colour-blue);
}

.panel:nth-child(3n-1) > .panelText{
    background-color: var(--sign-colour-yellow);
    color: white;
}

.panel:nth-child(3n) > .panelText{
    background-color: var(--sign-colour-red);
}

@media(max-width: 1000px){
    .panelDiv{
        grid-template-columns: 1fr;
    }
}

.outerSkipInfo{
    background-color: var(--alter-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: centern;
    gap: 1em;
    padding: 3em;
}

.outerSkipInfo > p
{
    font-size: 12px;
    color: rgb(71, 69, 69);
}

.infoContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.skipInfo{
    max-width: 400px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    border-radius: 5%;
    gap: 1em;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
}

.skipInfo > h4{
    text-align: center;
}

.skipInfo > p {
    text-align: center;
}

@media (max-width: 1200px)
{
    .infoContainer{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px)
{
    .infoContainer{
        grid-template-columns: 1fr;
    }
}




.skipDisplayDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.skipDisplayElem{
    aspect-ratio: 1.4/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--alter-background);

    position: relative;
    overflow: hidden;
}

.skipDisplayElem:nth-child(odd){
    background-color: lightgrey;
}

.skipDisplayTextbox{
    height: 80%;
    width: 80%;
    border: 10px solid white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
}

.skipDisplayTextbox > h3{
    color: white;
    font-family: 'Open Sans', sans-serif;
    z-index: 1;
}

@media (max-width:1000px){
    .skipDisplayDiv{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:600px){
    .skipDisplayDiv{
        grid-template-columns: 1fr;
    }
}






























.contactPanel
{
    background-color: var(--sign-colour-red);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2em;
}

.contactPanel > h2
{
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 25px;
    max-width: 900px;
    font-weight: 100;
}

.contactPanelBold
{
    font-weight: 900;
}
