:root
{
    --max-height: 85px;
    --min-height: 75px;

    --title-color:rgb(207,51,65);
    --regular-text: rgb(36, 35, 35);

    --sign-colour-blue: rgb(8,37,182);
    --sign-colour-yellow: rgb(255,191,64);
    --sign-colour-red: rgb(207,51,65);

    --alter-background: rgb(241,241,241);
}

body, html {
    height: 100%;
}

.headImageContainer{
    max-height: 500px;
    aspect-ratio: 1.7/1;
    width: 100%;
    overflow: hidden;

    background-color: var(--alter-background);

    position: relative;
}


.setIndexContainer{
    display: flex;
    position:absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.indexDot{
    cursor: pointer;
    font-size: 28px;
    width: 0.5em;
    height: 0.5em;

    margin: 0.5em;
    padding: 0.25em;
    border: 1px solid grey;
    border-radius: 50%;

    background-color: hsla(0, 0%, 95%, 0.397);
    transition: all 0.4s ease;
}

.arrow{
    cursor: pointer;
    font-size: 28px;
    
    color: transparent;
    transition: all 0.4s ease;
}

.arrow:hover{
    color: white;
}

.headImageContainer:hover > .rightArrow{
    left: 95%;
}

.headImageContainer:hover > .leftArrow{
    left: 5%;
}

.rightArrow{
    position: absolute;
    top: 50%;
    left: 105%;
    transform: translate(-50%, -50%) rotate(180deg);
}

.leftArrow{
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translate(-50%, -50%);
}

.indexDot:hover{
    background-color: var(--alter-background);
}

.selectedDot, .selectedDot:hover{
    background-color: rgba(255, 255, 255, 0.706);
}

.headImage{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 100% 60%;
}

.iconStyle{
    width: 1.5em;
    background-color: transparent;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding-bottom: 1em;
}

.headerDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 80%;
    gap: 15px;
    background-color: white;
    max-width: 1000px;
    -webkit-border-radius: 25px;
}

.headerDiv > h1{
    color: var(--title-color);
    text-align: center;
}

.headerTextDiv{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
}

.headerTextDiv > p{
    font-family: 'Open Sans', sans-serif;
    color: white;
    width: 80%;
}

@media (min-width: 600px){
    
    .headerDiv{
        transform: translateY(-50px);
    }

    .setIndexContainer{
        top: 82.5%;
    }
}


.outerPanelDiv{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: white;
}

.panelDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2em ;
    row-gap: 1em ;
    width: 100%;
    max-width: 80%;
    padding: 1em;
}

.panel{
    box-shadow: 0px 1px 10px rgb(140, 135, 135);
    border-radius: 5%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.panelImg{
    background-color: rgb(141, 134, 134);
    aspect-ratio: 1.7 /1;

    max-height: 300px;

    overflow: hidden;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;

    position: relative;
}

#hashImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
}

.infoImgCover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    transition: all 4s ease;
}

.imgInvisible{
    opacity: 0;
}

.panelText{
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    flex: 1;

    padding: 2em;
    padding-top: 1em;
    color: white;

}

.panelText > h3, p, .panelLink{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

.panelText > p{
    min-height: 5.5em;
}

.panelLink{
    max-width: 180px;
    min-height: 3.5em;
    align-self: center;

    padding: 0.5em;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.4s ease;
    -webkit-border-radius: 5px;
}


.panelText > ul{
    font-family: 'Open Sans', sans-serif;
    padding-left: 1em;
    padding-right: 1em;
    align-self: center;
}

.panel:nth-child(3n-2) > .panelText{
    background-color: var(--sign-colour-blue);
}

.panel:nth-child(3n-2) > .panelText > .panelLink{
    background-color: white;
    color: var(--sign-colour-blue);
}

.panel:nth-child(3n-2) > .panelText > .panelLink:hover{
    background-color: var(--sign-colour-blue);
    color: white;
    border: 3px solid white;
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    font-weight: 600;
}


.panel:nth-child(3n-1) > .panelText{
    background-color: var(--sign-colour-yellow);
}

.panel:nth-child(3n-1) > .panelText > .panelLink{
    background-color: white;
    color: var(--sign-colour-yellow);
}

.panel:nth-child(3n-1) > .panelText > .panelLink:hover{
    background-color: var(--sign-colour-yellow);
    color: white;
    border: 3px solid white;
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    font-weight: 600;
}

.panel:nth-child(3n) > .panelText{
    background-color: var(--sign-colour-red);
}

.panel:nth-child(3n) > .panelText > .panelLink{
    background-color: white;
    color: var(--sign-colour-red);
}

.panel:nth-child(3n)> .panelText > .panelLink:hover{
    background-color: var(--sign-colour-red);
    color: white;
    border: 3px solid white;
    box-shadow: 0px 1px 10px rgb(159, 153, 153);
    font-weight: 600;

}


@media(max-width: 1000px){
    .panelDiv{
        grid-template-columns: 1fr;
    }
}

.parallax{
    min-height: 300px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax1{
    background-image: url('../images/tipper.jpg');
}

.parallax2{
    background-image: url('../images/threeLorries.jpg');
}

.parallax3{
    background-image: url('../images/lineUp.jpg');
}

@media only screen and (max-device-width: 1366px) {
    .parallax {
      background-attachment: scroll;
      margin-bottom: 1em;
    }
}

.innerParallax{
    aspect-ratio: 1.7/1;
    max-height: 300px;
}

.socialDiv
{
    display: flex;
    justify-content: left;
    padding: 10%;
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: var(--alter-background);
}

.socialContainer
{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    max-width: 800px;
    font-family: 'Bebas Neue', 'Helvetica';
    padding: 2em;
}

.socialButtons
{
    display: flex;
    flex-direction: row;
    transform: translateX(-5px);
    align-items: center;
    justify-content: center;
}

.socialIconStyle
{
    width: 3em;
}

.socialTitle
{
    color: var(--regular-text);
    text-align: center;
}

.socialHighlight
{
    font-weight: 600;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.socialHighlight > p{
    font-weight: 900;
}

.socialParagraph
{
    font-size: 14px;
    text-align: center;
    max-width: 600px;
}

.socialImage
{
    display: none;
}

@media (min-width: 600px)
{
    .socialImage
    {
        display: block;
    }

    .socialDiv
    {
        justify-content: center;
    }
}

.outerContactPanel
{
    background-color: var(--alter-background);
}

.contactPanel
{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;

    padding: 2em;
    background-color: var(white);
}

.contactPanel > h2
{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 25px;
    max-width: 900px;
    font-weight: 100;
    max-width: calc(600px + 4em);
}

.contactPanelBold
{
    font-weight: 900;
}

