:root
{
    --backgorund-colour: rgb(51,48,48);
    --text-colour: white;

    --alter-background: rgb(241,241,241);

    --highlighted-text: rgb(47, 47, 163);
    --sign-colour-red: rgb(207,51,65);
}

input, button
{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color:  var(--text-colour);
    box-shadow: 1px 1px 8px lightblue;
    border-radius: 3px;
    padding-left: 5px;
}

input, textarea
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}


textarea
{
    background-color:  var(--text-colour);
    box-shadow: 1px 1px 8px lightblue;
    border-radius: 3px;
    padding: 5px;
}

h1
{
    font-family: 'Bebas Neue', 'Helvetica';
    color: var(--text-colour);
    text-align: center;
}

.contactDiv
{
    background-color: var(--backgorund-colour);
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4em;
    gap: 1em;
}

.outerIntroDiv
{
    background-color: var(--sign-colour-red);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 1.5em;
    padding-bottom: 1.5em;
    gap: 0.5em;
}

.contactIntro
{
    font-family: 'Open Sans', sans-serif;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.contactIntro > p
{
    color: white;
    text-align: center;
    font-size: 20px;
}

.contactSpan
{
    font-weight: 900;
    transition: all 0.4s ease;
}

.contactSpan:hover{
    color: var(--alter-background);
    font-size: 22px;
}

.overflowFormDiv
{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.overflowFormElement
{
    height: 30px;
    margin-bottom: 0;
}

@media(max-width: 600px){

    .overflowFormDiv .overflowFormElement:first-child{
        margin-bottom: 1em;
    }
}

@media(min-width: 600px){

    .overflowFormDiv .overflowFormElement:first-child{
        margin-right: 1em;
    }

    .overflowFormDiv{
        flex-direction: row;
    }

    .overflowFormElement{
        flex: 1;
    }
}
  

.formLayout
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--text-colour);
    gap: 1em;
    align-content: stretch;

    width: 90%;
    max-width: 600px;
}

.formElement
{
    height: 40px;
}

button
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 40px;
    color: black;
}

.messageFormElement
{
    height: 120px;
}

.formElement:focus,
.formElement:hover
{
    outline-width: 10px;
}

.iconStyle
{
    margin-top: 1em;
    width: 1.5em;
    background-color: white;
    border-radius: 50%;
}

.ReCAPTCHAElem{
    align-self: center;
}

.disabledButton{
    cursor: not-allowed;
    color: grey;
}

.formPara{
    color: var(--backgorund-colour);
    font-weight: 800;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.disabledButton:hover + .formPara{
    color: white;
}

.symbolContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.4 ease;
}

.symbolCircle{
    width: 3.5em;
    height: 3.5em;
    border: 3px solid white;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.acceptSymbol{
    width: 2.5em;
}

.warnSymbol{
    width: 2.5em;
    transform: translateY(-0.25em);
}

.visibleSymbol{
    animation-name: symbolAnimation;
    animation-duration: 4s;
}

.circleDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
}

.circleDiv > p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    padding-left: 3em;
    padding-right: 3em;
}

.warnLink{
    text-decoration: underline;
    transition: all 0.4s ease;
}

.warnLink:hover{
    color: rgb(179, 172, 172);
}

.outerWaiting{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
}

.waitingCircle{
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    border: 3px solid var(--backgorund-colour);
    border-left-color: white;
    animation:3s rotate infinite linear  ;
    transform-origin:50% 50%;
    -moz-animation: 3s rotate infinite linear  ;
    -moz-transform-origin: 50% 50%; 
    -webkit-animation: 3s rotate infinite linear  ;
    -webkit-transform-origin:50% 50%;  
}

@-moz-keyframes rotate {
    0%    { -moz-transform: rotate(0); } 
    100% { -moz-transform: rotate(360deg); }  
}

@-webkit-keyframes rotate {
    0%    { -webkit-transform: rotate(0); }
    100% { -webkit-transform: rotate(360deg); } 
}

@keyframes rotate{
    0%    { transform: rotate(0);}
    100% { transform: rotate(360deg);}
}

@keyframes symbolAnimation{
    0%      {width: 2.5em;}
    10%     {width: 2.5em;}
    50%     {width: 4em;}
    75%     {width: 2.5em;}
    100%    {width: 2.5em;}
}

.invisible{
    display: none;
}